/* App.css */

* {
  box-sizing: border-box;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0 auto;
}

.first-page-left {
  top:0;
  width: 85%;
  height: 100%;
  border-style: solid;
  z-index: 1000;
  left: 0%; /* Start at the zipper position */
}

.first-page-right,
.first-page-left,
.second-page {
  position: absolute;
  display: block;
  top: 0;
  left:0;
  height: 100%;
}

.first-page-right {
  left: 85%; /* Start at the zipper position */
  width: 85%; /* Same width as the left half */
  z-index: 1000;
  border-style: solid;
}

.project-second-slide {
  overflow: auto;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  position: fixed;        /* Ensures the container takes up the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.second-page {
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.content,
.second-page h1 {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bg-wrapper {
  position:absolute;
  display: block;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}


.links {
  transform: translateX(0%) translateY(-100%);
}


#backgroundCanvas {
  top: 0;
  left: 0;
}

.zipper-handle {
  position: relative;
  transform: translate(
    -50%,
    -50%
  ); /* Shift left and up by half its dimensions */
  z-index: 3000;
  width: 3em;
  height: 3em;
  left: 70%;
  border-radius: 5px 5px 15px 15px;
  border: 0.5em solid transparent;
  border-top: 4em solid #2b2b2b;
  border-bottom: 0;
  top: 0;
  border-style: inset;
  transform: translateX(-50%);
}

.zipper-tab {
  position: absolute;
  transform: translateX(-50%) rotateX(0deg);
  transition: transform 0.2s ease-out;
  transform-origin: center top;
  top: -2em; /* Offset down relative to .zipper-handle */
  width: 2em;
  height: 6em;
  border-style: inset;
  border-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(rgb(202, 202, 202), rgb(87, 87, 87));
  border-radius: 5px; /* Optional for rounded edges */
  transform-origin: center top;
}

.zipper-tab.flipped {
  background-color: #c0c0c0;
  background-image: linear-gradient(rgb(202, 202, 202), rgb(87, 87, 87));
  border-style: outset;
  transform: rotate(-180%);
}

.zipper-content {
  font-size: 24px;
}

.hook {
  position: relative;
  width: 0.8em;
  height: 2em;
  background-image: linear-gradient(
    rgb(212, 212, 212),
    rgb(172, 172, 172),
    rgb(172, 172, 172),
    rgb(255, 255, 255)
  );
  transform: translateX(75%) translateY(-150%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.5em;
  z-index: 2002;
  transition: transform 0.3s ease; /* Smooth lowering animation */
}

.zipper-tab.flipped div {
  transform: translateX(50%) translateY(170%); /* Flip text */
}

.depth {
  display: inline-block;
  left: 13px;
  bottom: 13px;
  content: "Backend"; /* Use the "title" attribute for text duplication */
  padding: 2px; /* Smaller padding for better fit */
  transform: translate(-50%, -50%) rotate(-90deg); /* Center and rotate text */
  color: rgb(0, 0, 0); /* Adjust as needed */
  font: bold 17px Arial, sans-serif; /* Smaller font size */
  position: absolute;
}

.depth:before,
.depth:after {
  content: attr(title); /* Use the "title" attribute for text duplication */
  padding: 2px; /* Smaller padding for shadows */
  color: rgba(107, 107, 107, 0.4); /* Shadow-like color */
  position: absolute;
}

.depth:before {
  top: 0.5px; /* Adjust for smaller shadow offset */
  left: 0.5px;
}

.depth:after {
  top: 1px;
  left: 1px;
}

.zipper-tab.flipped .depth {
  content: attr(title); /* Use the "title" attribute for text duplication */
  left: 13px;
  bottom: 15px;
  font: bold 17px Arial, sans-serif; /* Adjust font size */
}

/* Title */
.title {
  position: 'absolute';
  top: '20px'; 
  left: '50%';
  fontSize: '2rem';
  fontWeight: 'bold';
  color: '#fff';
  zIndex: 10;
}
/* Slider */
.project-slider {
  position: relative;
  left:5vw;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 2000;
}

.project-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.project-content {
  text-align: center;
}

.project-content img {
  max-width: 100%;
  height: auto;
  z-index: 2000;
}

.navButtonDiv{
  position: relative;
  top: -48px;
}

.nav-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  z-index: 2000;
}
/*Language Switch*/
.wrapper {
  height: 10em;
  background: -o-linear-gradient(315deg, #8175fe, #89befe);
  background: linear-gradient(135deg, #8175fe, #89befe);
}
.box {
  width: 20em;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(0%, -50%);
}
.cont {
  width: 7em;
  height: 3.25em;
  left: 0.5em;
  background-color: #24232386;
  -webkit-box-shadow: 0 2.5em 4.6em rgba(0, 0, 0, 0.3);
  box-shadow: 0 2.5em 4.6em rgba(0, 0, 0, 0.3);
  border-radius: 0.3em;
  position: absolute;
  -webkit-transform: skewX(-7deg);
  -ms-transform: skewX(-7deg);
  transform: skewX(-7deg);
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  outline: none;
  height: 2.8em;
  width: 6.25em;
  background:
    url('../public/img/Flag_of_Quebec.svg');
  background-size: cover;
  background-position: center;
}
input[type="checkbox"]:before {
  font-size: 1em;
  content: " FR ";
  position: absolute;
  width: 2em;
  height: 2em;
  background-color: #2d2936;
  color: #eeeeee;
  top: 0.42em;
  left: 0.42em;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}
input[type="checkbox"]:checked {
 
    background-image:
        linear-gradient(0deg, #1b1994, #1b1994),
        repeating-linear-gradient(180deg,
            #b22222, #b22222 7.7%, white 7.7%, white 15.4%);
    background-size: 40% 53.85%, 100% 100%;
    background-repeat: no-repeat;
    background-position: top left;
}
input[type="checkbox"]:checked:before {
  content: "EN";
  left: 4.1em;
  color: #eeeeee;
}